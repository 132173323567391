<template>
  <b-modal :active="loggedOut" has-modal-card trap-focus :destroy-on-hide="true" :can-cancel="false" style="min-width: 50%;">
    <div class="modal-card h-100" style="width: auto;">
      <header class="modal-card-head radius-0">
        <p class="modal-card-title">Login</p>
      </header>
      <section class="modal-card-body" style="display: block !important; min-width: 37vw;">
        <form>
          <b-field label="Email">
            <b-input v-model="email" type="email" icon-pack="fas"></b-input>
          </b-field>

          <b-field label="Password">
            <b-input v-model="password" type="password" icon-pack="fas" password-reveal v-on:keyup.enter="login"></b-input>
          </b-field>

          <b-notification v-if="errorMessage" animation="fade" :closable="false" :message="errorMessage" type="is-danger" has-icon icon-pack="fa" role="alert"></b-notification>
        </form>
      </section>
      <footer class="modal-card-foot radius-0">
        <button v-on:click="login" class="btn btn-dark btn-lg btn-block text-light" type="button">Login</button>
      </footer>
    </div>

    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import firebaseErrors from '../assets/js/firebaseErrors';

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      loggedOut: false,
      errorMessage: '',
    }
  },
  methods: {
    login() {
      this.isLoading = true;
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password
      }).then(() => {
        this.isLoading = false;
        this.loggedOut = false;
        this.$router.go();
      }).catch(error => {
        this.isLoading = false;
        firebaseErrors[error.code] ? this.errorMessage = firebaseErrors[error.code] : this.errorMessage = error.message;
      });
    }
  },
  mounted() {
    const app = Vue.prototype.$firebaseApp
    const auth = getAuth(app)

    onAuthStateChanged(auth, user => {
      if (user) {
        this.loggedOut = false
      } else {
        this.loggedOut = true
        this.$store.dispatch('signOut');
      }
    });
  }
}
</script>
